import React from "react"

export default function Footer() {
  return <nav className="w-full flex items-center justify-between flex-wrap bg-white p-10 lg:px-0">
    <div className="w-full block flex-grow lg:flex lg:items-center lg:w-auto">
      <div className="text-sm lg:flex-grow">
        <div className="flex items-center flex-shrink-0 mr-6">
          <p className="text-lg text-grey-600">Copyright 2020. <a href="https://orangeandcrane.com" target="_blank" className="text-accent">Orange and Crane Innovations Inc.</a> All Rights Reserved.</p>
        </div>
      </div>
      <div>
        <div className="text-sm lg:flex-grow">
          <a href="https://admin.ciergio.com/terms-&-conditions" target="_blank" className="block mt-4 px-4 lg:inline-block lg:mt-0 text-grey-600 text-lg hover:text-black mr-4">
            Terms & Conditions
          </a>
          <a href="https://admin.ciergio.com/privacy-policy" target="_blank" className="block mt-4 px-4 lg:inline-block lg:mt-0 text-grey-600 text-lg hover:text-black mr-4">
            Privacy Policy
          </a>
        </div>
      </div>
    </div>
  </nav>
}